
import { mapGetters } from 'vuex'
import { Notification } from '~/components/molecules'
import SmoothWrapper from '~/components/utils/smooth-wrapper.vue'

export default {
  components: {
    Notification,
    SmoothWrapper,
  },
  computed: {
    ...mapGetters('ui/notifications', ['notifications']),
  },
  mounted() {
    this.$notifications.restoreDeferredNotifications()
  },
}
